import React, { useState, useEffect } from "react";
import Seo from "@components/common/Seo";
import Layout from "@components/layouts/UserLayout";
import { Link } from "gatsby";
import { SiMaildotru, SiOnlyfans } from "react-icons/si";
import { AiOutlineLoading } from "react-icons/ai";
import { toast } from "react-toastify";
import useAuth from "@components/hooks/useAuth";
import { navigate } from "gatsby";
import InputObject from "@components/common/InputObject";

const Component = ({ location }) => {
    const { login, loading } = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState(null);

    useEffect(() => {
        if (process.env.GATSBY_AUTH_ENABLED !== "true") {
            navigate("/");
        }
    }, []);

    const onSubmit = async e => {
        e && e.preventDefault();

        if (loading) {
            return;
        }

        const success = await login({
            identifier: email,
            password: password,
        });

        if (success) {
            toast.success("Sesión iniciada con éxito. En breve será redirigido.", {
                position: "bottom-center",
                closeOnClick: true,
                pauseOnHover: true,
                autoClose: 2000,
            });

            // Reseteamos datos
            setEmail("");
            setPassword("");
            setLoginError(null);

            // Redireccionamos si es el caso
            let redirect = "";

            try {
                const params = new URLSearchParams(location.search);
                redirect = params.get("redirect");
            } catch (err) {
                console.log(err?.message);
            }

            // Redireccionamos en caso que haya alguna url en concreto
            setTimeout(() => {
                navigate(redirect && redirect !== "" ? redirect : "/private");
            }, 3000);
        } else {
            setLoginError(
                "Usuario o contraseña incorrectos. Revisa los datos y vuelve a intentarlo.",
            );
        }
    };

    return (
        <>
            <Seo title="Iniciar sesión de usuario" />
            <Layout image={"bgs/bgClassroom"}>
                <div className="flex gap-5 justify-between">
                    <div className="-mt-6 text-gray-500 text-xs">
                        ¿No tienes una cuenta?{" "}
                        <Link
                            className="text-ccyan hover:border-ccyan border-b border-transparent duration-300"
                            to="/crear-cuenta"
                        >
                            Regístrate ahora
                        </Link>
                    </div>

                    <div className="-mt-6 text-gray-500 text-xs text-right">
                        <Link
                            className="text-ccyan hover:border-ccyan border-b border-transparent duration-300"
                            to="/recuperar-cuenta"
                        >
                            ¿Olvidaste tu contraseña?
                        </Link>
                    </div>
                </div>

                <div className="flex h-full">
                    <div className="my-auto">
                        <h1 className={styles.title}>Inicar sesión de usuario</h1>
                        <h2 className={styles.subtitle}>
                            Anim irure amet eiusmod occaecat excepteur ipsum ut Lorem. Duis sit quis
                            est cupidatat. Cillum sunt id non consequat dolor est officia enim
                            reprehenderit nisi est. Minim nisi quis deserunt.
                        </h2>

                        <form className={styles.form} onSubmit={onSubmit}>
                            <InputObject
                                title="Correo electrónico"
                                type="email"
                                id="email"
                                name="email"
                                required={true}
                                placeholder="Dirección de correo electrónico"
                                value={email}
                                setValue={setEmail}
                                icon={SiMaildotru}
                                tabIndex="0"
                            />

                            <InputObject
                                title="Contraseña"
                                type="password"
                                id="password"
                                name="password"
                                required={true}
                                placeholder="Contraseña"
                                value={password}
                                setValue={setPassword}
                                icon={SiOnlyfans}
                                tabIndex="0"
                            />

                            <div>
                                <button className={styles.actionButton(loading)} disabled={loading}>
                                    {loading && <AiOutlineLoading className={styles.loadingIcon} />}
                                    <span>Iniciar sesión</span>
                                </button>

                                <div className={styles.loginError(loginError)}>
                                    {loginError || ""}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Layout>
        </>
    );
};

const styles = {
    title: "text-3xl lg:text-4xl font-bold text-gray-700",
    subtitle: "text-gray-600 text-sm mt-2 md:mt-0",
    form: "mt-10 flex flex-col space-y-10",
    loadingIcon: "text-center w-4 h-4 animate-spin duration-300",
    actionButton: loading =>
        `text-white text-center text-sm px-5 py-3 text-center w-full bg-ccyan rounded hover:bg-ccyan-dark duration-300 flex items-center justify-center space-x-5 ${
            loading ? "cursor-not-allowed" : "cursor-pointer"
        }`,
    loginError: error =>
        `mt-5 text-xs text-red-600 text-center duration-300 ${error ? "opacity-100" : "opacity-0"}`,
    signup: "mt-10",
};

export default Component;
